import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    loading: false,
    error: false,
}

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

      loginStart: (state) => {
        state.loading = true;
        state.error = false;
        state.user = null;
      },
      loginSuccess: (state, action) => {
        state.loading = false;
        state.error = false;
        state.user = action.payload;
      },
      loginFailure: (state) => {
        state.loading = false;
        state.error = true;
        state.user = null;
      },
      logout: (state) => {
          return initialState;
      }

    },
  })

export const { loginStart, loginSuccess, loginFailure, logout } = UserSlice.actions;

export default UserSlice.reducer;
