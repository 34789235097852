import './users.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function UserCpnt({ team, expert, domain, search_domain, search_term }) {

    let set_domain = '';

	const [user_data, setUserData] = useState([]);
    const [loadUsers, setLoadUsers] = useState("true");

    const loggedUser = useSelector(state=>state.user.user);

    if((domain == null)&&(search_domain == null)) {
        set_domain = '';
    }
    else if((domain == null)&&(search_domain != null)) {
        set_domain = search_domain;
    }
    else if((domain != null)&&(search_domain == null)) {
        set_domain = domain;
    }
    else
    {
        set_domain = search_domain;
    }

	useEffect(()=>{

        const getUsers = async()=> {

            if(expert==='true')
            {
                try{                    
                    const usersRes = await axios.get(process.env.REACT_APP_API_KEY+"/experts/?domain="+set_domain+'&search_term='+search_term);
                    setUserData(usersRes.data);

                    setLoadUsers("false");
                }
                catch(err) {
                    toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
                }
            }
            else
            {
                if(team ==='true')
                {
                    try{
                        const usersRes = await axios.get(process.env.REACT_APP_API_KEY+"/employee/");
                        setUserData(usersRes.data);

                        setLoadUsers("false");
                    }
                    catch(err) {
                        toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast2' });
                    }
                }
                else
                {
                    try{
                        const usersRes = await axios.get(process.env.REACT_APP_API_KEY+"/users");
                        setUserData(usersRes.data);

                        setLoadUsers("false");
                    }
                    catch(err) {
                        toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast3' });
                    }
                }
            }
        }

        // functions call 

        getUsers();

    });

    const removeEmployee = (employee_id, employee_name)=> {

        Swal.fire({
          title: 'Effacer l\'employée '+employee_name,
          text: "Vous ne pourrez pas revenir en arrière !",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, effacer !',
          cancelButtonText: 'Non !'
        }).then((result) => {
          if (result.isConfirmed) {

            deleteEmployee(employee_id);

          }
        })

    }

    const deleteEmployee = async(employee_id) => {

        toast.info("Opération en cours !", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000, toastId: 'toast4' });

        try{
            const legal_kit_document_post = await axios.put(process.env.REACT_APP_API_KEY+"/employee/delete/"+employee_id, {}, {
                headers: {
                    Authorization: loggedUser.token
                }
            });

            toast.success(legal_kit_document_post.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast5' });
        }
        catch(err) {
            toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast6' });
        }

    }

	return(

		<>
            {loadUsers === 'true' ? 

                <div className="data-loader">

                    <TailSpin 
                        height="40"
                        width="40"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />

                </div>

                : "" 
            }

			{user_data?.map((user)=> (

                <div className="col-lg-6 col-md-6 col-sm-6 mb30 pull-left clear">

                    {team ==='true' ? 

        				<div className="user-list">

                            {loggedUser && loggedUser.is_admin === true ? <div className="remove-expertise remove-kit" onClick={ e=>removeEmployee(user._id, user.employee.first_name+" "+user.employee.last_name) }>X</div> : '' }
                        
        					<img src={user.employee.profile_image} className="fp-image img-fluid" alt=""/>
                            <div className="details">
                                <h4 className="user-details-h2">{user.employee.first_name} {user.employee.last_name}</h4>
                                <p className="user-details-p"><b>{user.position}</b></p>
                                <p className="user-details-p">{user.employee.email}</p>

                                <div className="fpwow-icons">
                                    <a href="{user.employee.facebook_profile}" target="_blank"><i className="fa fa-facebook fa-lg"></i></a>
                                    <a href="{user.employee.twitter_profile}" target="_blank"><i className="fa fa-twitter fa-lg"></i></a>
                                    <a href="{user.employee.linkedin_profile}" target="_blank"><i className="fa fa-linkedin fa-lg"></i></a>
                                </div>
                            </div>
                            <div className="view-button">
                                <Link to={`/profile/?param=${user.employee._id}`}><button><i className="fa fa-arrow-right"></i></button></Link>
                            </div>

                        </div>
                    :
                        <div className="user-list">
                            
                            <img src={user.profile_image} className="fp-image img-fluid" alt=""/>
                            <div className="details">
                                <h4 className="user-details-h2">{user.first_name} {user.last_name}</h4>
                                <p className="user-details-p">{user.email}</p>

                                <div className="fpwow-icons">
                                    <a href="{user.facebook_profile}" target="_blank"><i className="fa fa-facebook fa-lg"></i></a>
                                    <a href="{user.twitter_profile}" target="_blank"><i className="fa fa-twitter fa-lg"></i></a>
                                    <a href="{user.linkedin_profile}" target="_blank"><i className="fa fa-linkedin fa-lg"></i></a>
                                </div>
                            </div>
                            <div className="view-button">
                                <Link to={`/profile/?param=${user._id}`}><button><i className="fa fa-arrow-right"></i></button></Link>
                            </div>

                        </div>
                    }

                </div>

            ))}
		</>
	)

}