import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    companyData: {},
    loading: false,
    error: false,
}

export const CompanySlice = createSlice({
    name: 'companyData',
    initialState,
    reducers: {

      companyDataFetchStart: (state) => {
        state.loading = true;
        state.error = false;
        state.companyData = {};
      },
      companyDataFetchSuccess: (state, action) => {
        state.loading = false;
        state.error = false;
        state.companyData = action.payload;
      },
      companyDataFetchFailure: (state) => {
        state.loading = false;
        state.error = true;
        state.companyData = {};
      }

    },
  })

export const { companyDataFetchStart, companyDataFetchSuccess, companyDataFetchFailure } = CompanySlice.actions;

export default CompanySlice.reducer;
