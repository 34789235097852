import './about.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CKeditor from '../../components/CKeditor';
import { TailSpin, Circles, RotatingLines } from  'react-loader-spinner';

import UserCpnt from '../../components/users/users';

import { companyDataFetchStart, companyDataFetchSuccess, companyDataFetchFailure } from '../../redux/companySlice';

export default function About() {

    const base_url = process.env.REACT_APP_BASE_KEY;
    
    const [company_details, setCompanyData] = useState([]);
    const [team_members_data, setTeamMembersData] = useState([]);
    const [loadTeamMembers, setLoadTeamMembers] = useState("true");
    const [loadCompanyData, setLoadCompanyData] = useState("true");

	const loggedUser = useSelector(state=>state.user.user);
	const stored_host_company_data = useSelector(companyDataFetchSuccess);

	useEffect(()=> {    

		window.scrollTo(0, 0);

        const getCompanyData = async()=>{
            const companyRes = await axios.get(process.env.REACT_APP_API_KEY+"/company/?host_organization=true");
            setCompanyData(companyRes.data);
            setLoadCompanyData("false");
        }

        if (Object.keys(stored_host_company_data.payload.companyData.companyData).length === 0) {
            getCompanyData();          
        }
        else
        {
            setCompanyData(stored_host_company_data.payload.companyData.companyData);
            setLoadCompanyData("false");
        }

		const getTeamData = async()=> {

			try{
				const team_members = await axios.get(process.env.REACT_APP_API_KEY+'/events/');
				setTeamMembersData(team_members.data);

				setLoadTeamMembers("false");
			} catch(err) {
				setLoadTeamMembers("true");
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		// functions call

		getTeamData();

	}, []);

	return (

		<>
			<div className="top-section">.</div>

			{loadCompanyData === 'true' ? 

                <div className="data-loader">

                    <TailSpin 
                    			height="40"
                                width="40"
                                color="#000"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                    />

                </div>

           	: "" 
            }

            {company_details?.map((company)=>(

            	<>
		            <section aria-label="section" data-bgcolor="#ffffff">
		                <div className="container">
		                	<div className="row align-items-center">
		                	
			                    <div className="col-md-12">
			                    
			                    	{loggedUser && loggedUser.is_admin === true ? 

					            		<section className="text-light search-section">
				                            <div className="container">
				                                <div className="row align-items-center">

				                                    <div className="col-md-2 text-lg-right text-sm-center">
				                                        <Link to={`/editer-les-donnees-d-entreprise/?param=${ company._id}`} className="clear-button">Editer </Link>
				                                    </div>

				                                </div>
				                            </div>
				                        </section>

				                    : "" }

			                        <span className="p-title"><br/><br/>Qui sommes-nous?</span><br/>
			                        <h2>{ company.name }</h2>
			                        <div className="image-round-blank-background">
			                        	<br/>
			                            <img src={base_url + "/images/d1.jpg"} alt="" />
			                            <br/><br/><br/><br/>
			                        </div>
			                        {typeof company.description === 'string' ? parse(company.description) : null}
			                    </div>
			                </div>
			            </div>
		            </section>
		            <section id="section-text" className="text-light about-section">
		                <div className="container">
		                    <div className="row align-items-center">
		                        <div className="col-lg-12 wow fadeInRight missions-section" data-wow-delay=".6s">
		                        	<h5><i class="fa fa-tasks" aria-hidden="true"></i> &nbsp; Mission</h5>
		                            <p>
		                                {typeof company.mission === 'string' ? parse(company.mission) : null}
		                            </p>
		                        </div>
		                        <div className="col-lg-12 wow fadeInRight missions-section" data-wow-delay=".6s">
		                        	<h5><i class="fa fa-eye" aria-hidden="true"></i> &nbsp; Vision</h5>
		                            <p>
		                                {typeof company.vision === 'string' ? parse(company.vision) : null}
		                            </p>
		                        </div>
		                        <div className="col-lg-12 wow fadeInRight missions-section" data-wow-delay=".6s">
		                        	<h5><i class="fa fa-road" aria-hidden="true"></i> &nbsp; Objectif</h5>
		                            <p>
		                                {typeof company.objective === 'string' ? parse(company.objective) : null}
		                            </p>
		                        </div>
		                    </div>
		                </div>
		            </section>
                    <section aria-label="section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <br/><br/>
                                    <h2>Notre Equipe</h2>
                                    <div className="small-border"></div>

                                    {loggedUser && loggedUser.is_admin === true ? 

                                        <div className="text-lg-right text-sm-center">
                                            <Link to={{pathname: '/ajouter-un-employe/', search: '?param='+company._id}} className="add-database">Ajouter un employé</Link>
                                        </div>

                                    : "" }

                                </div>

                                <UserCpnt team={'true'} />

                            </div>
                        </div>
                    </section>

            	</>

            ))}
        </>
                
	)

}
